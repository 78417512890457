/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.


  var filterFunction = function() {

    selected = $('select.multiple-select.crop').multipleSelect('getSelects');

    filterText = '';
    selected.forEach(function(element){
      filterText += '.'+element+', ';
    });
    filterText = filterText.substring(0, filterText.length-2);


    $quicksearch = $('#txt_search_products');
    const text = $quicksearch.val();
    const words = text.split(' ');
    const expression = words.join('|');
    qsRegex = new RegExp(expression, 'gi' );
    var $this = $(this);
    // console.log(filterText);
    var searchResult = qsRegex ? $this.text().match( qsRegex ) : true;
    var buttonResult = filterText ? $this.is( filterText ) : true;
    return searchResult && buttonResult;
  };

  function clearSelection(){
    // clearSelection = false;

    // retu÷rn clearSelection;
  }


  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.search-icon.menu-item a').on('click',function(e){
            e.preventDefault();

            $('.search-slider-wrapper').toggleClass('active');
            $('header').addClass('scroll-active');
        });

        $('.menu-item-has-children')
          .mouseover(function(e){
            // console.log($(this).children('.sub-menu'));
            $(this).children('.sub-menu').addClass('active');
            $(this).children('a').addClass('active');
            // console.log($(e.target).closest('ul.sub-menu'));


          })
          .mouseout(function(){
            $(this).children('.sub-menu').removeClass('active');
            $(this).children('a').removeClass('active');


          });


        $('#nav-icon1').on('click',function(){
          // console.log('test');
          $('.nav-mobile').toggleClass('active');
        });

        $('select.multiple-select.target').multipleSelect({
          placeholder: 'TARGET'
        });

        $('select.multiple-select.crop').multipleSelect({
          placeholder: 'CROP',
          onClick:function(view){
            selected = $('select.multiple-select.crop').multipleSelect('getSelects');
            // console.log('test', this);
            console.log('selected', $(this).attr('disabled'));
            if(checkForClearSelection()){
              $('.clear-selection').addClass('active');
            }else{
              $('.clear-selection').removeClass('active');
            }
            filterText = '';
            selected.forEach(function(element){
              filterText += '.'+element+', ';
            });
            filterText = filterText.substring(0, filterText.length-2);
            $('.products-holder').isotope({ filter: filterText });

          },
          onCheckAll:function(){
            selected = $('select.multiple-select.crop').multipleSelect('getSelects');

            if(checkForClearSelection()){
              $('.clear-selection').addClass('active');
            }else{
              $('.clear-selection').removeClass('active');
            }

            filterText = '';
            selected.forEach(function(element){
              filterText += '.'+element+', ';
            });
            filterText = filterText.substring(0, filterText.length-2);
            $('.products-holder').isotope({ filter: filterText });

          },
          onUncheckAll:function(){
            selected = $('select.multiple-select.crop').multipleSelect('getSelects');

            if(checkForClearSelection()){
              $('.clear-selection').addClass('active');
            }else{
              $('.clear-selection').removeClass('active');
            }
          }
        });

        $('select.multiple-select.treatment').multipleSelect({
          placeholder: 'TREATMENT',
          single:true,
          onClick:function(){
            // selected = $('select.multiple-select.treatment').multipleSelect('getSelects');

            if(checkForClearSelection()){
              $('.clear-selection').addClass('active');
            }else{
              $('.clear-selection').removeClass('active');
            }
          }

        });






        $('.clear-search-selection').on('click', function(e){
          console.log('1');
          e.preventDefault();



          console.log('Clear seletion start');

          if($('select.multiple-select.treatment').attr('disabled')!== 'disabled'){
            selected = $('select.multiple-select.treatment').multipleSelect('getSelects');

            if(selected.length>0){
              $('select.multiple-select.treatment').multipleSelect('uncheckAll');
            }
          }

          if($('select.multiple-select.crop').attr('disabled')!== 'disabled'){
            selected = $('select.multiple-select.crop').multipleSelect('getSelects');

            if(selected.length>0){
              $('select.multiple-select.crop').multipleSelect('uncheckAll');
            }
          }


          if($('#txt_search_products').attr('disabled') !== 'disabled'){
            val = $('#txt_search_products').val();

            if(val !== ""){
              $('#txt_search_products').val('');
            }
          }
          console.log('Clear seletion');









          $('.clear-selection').removeClass('active');
          $('#btn_search_products').trigger('click');

        });

        function checkForClearSelection(){
          clearSelection = false;

          if($('select.multiple-select.treatment').attr('disabled')!== 'disabled'){
            selected = $('select.multiple-select.treatment').multipleSelect('getSelects');

            if(selected.length>0){
              clearSelection = true;
            }
          }

          if($('select.multiple-select.crop').attr('disabled')!== 'disabled'){
            selected = $('select.multiple-select.crop').multipleSelect('getSelects');

            if(selected.length>0){
              clearSelection = true;
            }
          }


          if($('#txt_search_products').attr('disabled') !== 'disabled'){
            val = $('#txt_search_products').val();

            if(val !== ""){
              clearSelection=true;
            }
          }

          return clearSelection;
        }




        $('#btn_search_products').on('click', function(){
          // console.log('clicked');

          if(! ($('body').hasClass('fungicides') || $('body').hasClass('herbicides') || $('body').hasClass('specialty') || $('body').hasClass('insecticides')  || $('body').hasClass('foliarnutrients')  ||  $('body').hasClass('herbicides') ) ){
            // console.log('home');

            selected = $('select.multiple-select.treatment').multipleSelect('getSelects');
            // console.log(selected);
            if(selected.length == 1){
              //redirect to a single product page
              // console.log(selected[0]);
              switch (selected[0]) {

                case 'fungicides':
                  // console.log('test-fungicides');
                  var crop = $('select.multiple-select.crop').multipleSelect('getSelects');
                  sessionStorage.setItem("crop", crop);
                  sessionStorage.setItem("searchText", $('#txt_search_products').val());
                  window.location.href ='/fungicides';
                  break;
                case 'herbicides':
                  // console.log('test-herbicides');
                  var crop = $('select.multiple-select.crop').multipleSelect('getSelects');
                  sessionStorage.setItem("crop", crop);
                  sessionStorage.setItem("searchText", $('#txt_search_products').val());
                  window.location.href ='/herbicides';
                  break;
                case 'specialty':
                  // console.log('test-herbicides');
                  var crop = $('select.multiple-select.crop').multipleSelect('getSelects');
                  sessionStorage.setItem("crop", crop);
                  sessionStorage.setItem("searchText", $('#txt_search_products').val());
                  window.location.href ='/specialty';
                  break;
                case 'insecticides':
                  // console.log('test-insecticides');
                  var crop = $('select.multiple-select.crop').multipleSelect('getSelects');
                  sessionStorage.setItem("crop", crop);
                  sessionStorage.setItem("searchText", $('#txt_search_products').val());
                  window.location.href ='/insecticides';
                  break;
                case 'foliarnutrients':
                  // console.log('test-insecticides');
                  var crop = $('select.multiple-select.crop').multipleSelect('getSelects');
                  sessionStorage.setItem("crop", crop);
                  sessionStorage.setItem("searchText", $('#txt_search_products').val());
                  window.location.href ='/foliarnutrients';
                  break;


                default:

              }
            }
            // console.log(selected);

          }


          $('.products-holder').isotope({
            filter: filterFunction

       });

        });


        function scroll_detect(){

            if(window.pageYOffset > 50){
              // console.log('test');

              //console.log('sticky');
                $('header').addClass('scroll-active');
                //  $('header .shoping-bag-container').css('top', '70px');
            }else{
              if($('body').hasClass('page-id-8')){
                // console.log('inside the pag-id-8');
                if(!$('.search-slider-wrapper').hasClass('active') ){
                  // console.log('inside the removeClass');
                  $('header').removeClass('scroll-active');
                }else{
                  // console.log('inside the addClass');
                  $('header').removeClass('add-active');

                }


              }else{
                $('header').addClass('scroll-active');
              }
            }

            //set automatic paddings
            // console.log($('.main-header-section > .container').css('margin-right'));
            var rightMargin = parseInt($('footer').css('margin-right')) +15;
            var leftMargin = parseInt($('footer').css('margin-left')) +15;
            // console.log("leftMargin", leftMargin+15);
            // console.log("rightMargin", rightMargin+15);
            $('.automatic-left-padding > .vc_column-inner').css('padding-left', leftMargin);
            $('.automatic-right-padding > .vc_column-inner').css('padding-right', rightMargin);



            if(window.innerWidth <= 767){
              $('.automatic-left-padding > .vc_column-inner').css('padding-right', rightMargin);
              $('.automatic-right-padding > .vc_column-inner').css('padding-left', leftMargin);
              // console.log('leftMargin', leftMargin);
              if(rightMargin == '0px'){
                $('.automatic-left-padding > .vc_column-inner').css('padding-right', '15px');
                $('.automatic-left-padding > .vc_column-inner').css('padding-left', '15px');
              }
              if(leftMargin == '0px'){
                $('.automatic-right-padding > .vc_column-inner').css('padding-left', '15px');
                $('.automatic-right-padding > .vc_column-inner').css('padding-right', '15px');
              }
            }else{
              $('.automatic-left-padding > .vc_column-inner').css('padding-right', '50px');
              $('.automatic-right-padding > .vc_column-inner').css('padding-left', '50px');
            }



            //set automatic bg padding

            var leftPosition = parseInt(window.innerWidth) - parseInt(rightMargin) - 359//width of the background;
            // console.log(leftPosition);
              $('.automatic-bg-postition').css('background-position', leftPosition+'px 181px');

        }
        setInterval(scroll_detect, 100);





      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Latest NEws page
    'latest_news': {
      init: function() {
        // JavaScript to be fired on the home page






      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS


        // $(function() {

        /*
        * alm_adv_filter()
        * https://connekthq.com/plugins/ajax-load-more/examples/filtering/advanced-filtering/
        *
        */

        var alm_is_animating = false;

        function alm_adv_filter(){

          if(alm_is_animating){
            return false; // Exit if filtering is still active
          }

          alm_is_animating = true;

          var obj= {},
             count = 0;

          // Loop each filter menu
          $('.advanced-filter-menu').each(function(e){
            var menu = $(this),
                type = menu.data('type'), // type of filter elements (checkbox/radio/select)
                parameter = menu.data('parameter'), // category/tag/year
                value = '',
                count = 0;

            // Loop each item in the menu
            if(type === 'checkbox' || type === 'radio'){ // Checkbox or Radio
              $('input:checked', menu).each(function(){
                count++;
                var el = $(this);

                // Build comma separated string of items
                if(count > 1){
                  value += ','+el.val();
                }else{
                  value += el.val();
                }
              });
            }

            if(type === 'select'){ // Select
              var select = $(this);
              value += select.val();
            }

            obj[parameter] = value; // add value(s) to obj

          });

          // console.log(obj);

          var data = obj;
          $.fn.almFilter('fade', '300', data); // Send data to Ajax Load More
        }

        $('.tag-checkbox').on('click', alm_adv_filter); // 'Apply Filter' button click



        /*
        * almFilterComplete()
        * Callback function sent from core Ajax Load More
        *
        */
        $.fn.almFilterComplete = function(){
          alm_is_animating = false; // clear animating flag
        };

      // })(jQuery);


      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'contact':{
      init:function(){

      },
      finalize: function(){
        // $('#input_1_5').multipleSelect();
        // $('#input_1_5').prettyDropdown();
        $('#input_1_5').niceSelect();

        jQuery(document).bind('gform_post_render', function(){
          $('#input_1_5').niceSelect();

        // code to trigger on AJAX form render

    });
      }
    },
    'fungicides': {
      init: function(){

      },
      finalize:function(){
        //setting the filter section
        var crop =  sessionStorage.getItem("crop");
        var searchText =  sessionStorage.getItem("searchText");

        if(crop != null || searchText != null){
          const cropArray = crop.split(',');
          console.log('array',cropArray);

        $('select.multiple-select.crop').multipleSelect('setSelects',cropArray );
        $('#txt_search_products').val( searchText);

        setTimeout(function(){
          $('.products-holder').isotope({
            filter: filterFunction

          });
        }, 500);
        sessionStorage.removeItem("crop");
        sessionStorage.removeItem("searchText");
        }
      }
    },
    'specialty': {
      init: function(){

      },
      finalize:function(){
        //setting the filter section
        var crop =  sessionStorage.getItem("crop");
        var searchText =  sessionStorage.getItem("searchText");

        if(crop != null || searchText != null){
          const cropArray = crop.split(',');
          // console.log('array',cropArray);

        $('select.multiple-select.crop').multipleSelect('setSelects',cropArray );
        $('#txt_search_products').val( searchText);

        setTimeout(function(){
          $('.products-holder').isotope({
            filter: filterFunction

          });
        }, 500);
        sessionStorage.removeItem("crop");
        sessionStorage.removeItem("searchText");
        }
      }
    },
    'herbicides': {
      init: function(){

      },
      finalize:function(){
        //setting the filter section
        var crop =  sessionStorage.getItem("crop");
        var searchText =  sessionStorage.getItem("searchText");

        if(crop != null || searchText != null){
          const cropArray = crop.split(',');
          // console.log('array',cropArray);

        $('select.multiple-select.crop').multipleSelect('setSelects',cropArray );
        $('#txt_search_products').val( searchText);

        setTimeout(function(){
          $('.products-holder').isotope({
            filter: filterFunction

          });
        }, 500);
        sessionStorage.removeItem("crop");
        sessionStorage.removeItem("searchText");
        }
      }
    },
    'insecticides': {
      init: function(){

      },
      finalize:function(){
        //setting the filter section
        var crop =  sessionStorage.getItem("crop");
        var searchText =  sessionStorage.getItem("searchText");

        if(crop != null || searchText != null){
          const cropArray = crop.split(',');
          // console.log('array',cropArray);

        $('select.multiple-select.crop').multipleSelect('setSelects',cropArray );
        $('#txt_search_products').val( searchText);

        setTimeout(function(){
          $('.products-holder').isotope({
            filter: filterFunction

          });
        }, 500);
        sessionStorage.removeItem("crop");
        sessionStorage.removeItem("searchText");
        }
      }
    },
    'foliarnutrients': {
      init: function(){

      },
      finalize:function(){
        //setting the filter section
        var crop =  sessionStorage.getItem("crop");
        var searchText =  sessionStorage.getItem("searchText");

        if(crop != null || searchText != null){
          const cropArray = crop.split(',');
          // console.log('array',cropArray);

        $('select.multiple-select.crop').multipleSelect('setSelects',cropArray );
        $('#txt_search_products').val( searchText);

        setTimeout(function(){
          $('.products-holder').isotope({
            filter: filterFunction

          });
        }, 500);
        sessionStorage.removeItem("crop");
        sessionStorage.removeItem("searchText");
        }
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
